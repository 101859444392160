import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useAbortController, useAppSelector} from "../../../../hooks";
import {selectToken} from "../../../../features/account/accountSlice";
import React, {ChangeEvent, Fragment, useEffect, useRef, useState} from "react";
import * as ApiHelper from "../../../../api/ApiHelper";
import {downloadAppVersion, getAppVersionInfo} from "../../../../api/ApiHelper";
import {logError} from "../../../../helpers/LogHelper";
import {Breadcrumbs} from "../../../Widgets/Breadcrumbs/Breadcrumbs";
import Loader from "../../../Widgets/Loader/Loader";
import {FetchError} from "../../../Widgets/FetchError/FetchError";
import {Button} from "../../../Widgets/Button/Button";
import {AppInfo} from "../../../../models/AppInfo";
import {formatUtcTimestamp} from "../../../../helpers/FormatHelper";
import ErrorResponse from "../../../../models/ErrorResponse";
import {Toast} from "../../../Widgets/Toast/Toast";
import DownloadIcon from "../../../../assets/svg/DownloadIcon";
import DeleteCommentIcon from "../../../../assets/svg/DeleteCommentIcon";
import {DialogConfirmation} from "../../../Widgets/DialogConfirmation/DialogConfirmation";

export const APP_BINORA_ANDROID = 1;
export const APP_BINORA_LT_ECG_ANDROID = 2;

interface AppsViewProps {
    type: number;
}

export function AppsView(props: AppsViewProps) {
    const {t} = useTranslation();
    const history = useNavigate();
    const token = useAppSelector(selectToken);
    const [controller] = useAbortController();
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [hasSaveError, setSaveErrorState] = useState(false);
    const [apps, setApps] = useState(undefined as Array<AppInfo> | undefined);
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(null as string | null);
    const uploadFileInput = useRef<HTMLInputElement>(null);
    const fetchData = () => {
        if (token) {
            setFetchingState(true);
            getAppVersionInfo(token.token, props.type, controller, data => {
                setApps(data);
                setErrorState(false);
                setFetchingState(false);
            }, error => {
                logError("Binora apps info fetch error", error);
                setFetchingState(false);
                setErrorState(true);
            });
        }
    };
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const saveErrorHandler = (error: ErrorResponse) => {
        logError("App version save error", error);
        setSaveErrorState(false);
        setSaveErrorState(true);
        setFetchingState(false);
    };
    const addApp = () => {
        uploadFileInput.current?.click();
    }
    const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            let userToken = token?.token;
            if (userToken) {
                setFetchingState(true);
                ApiHelper.uploadAppVersion(userToken, props.type, e.target.files[0], e.target.files[0].name, controller, fetchData, saveErrorHandler)
            }
        }
    };
    const onDownloadClick = (id: string) => {
        downloadAppVersion(id);
    };
    const onDeleteClick = (id: string) => {
        setShowDeleteConfirmationDialog(id);
    };
    const deleteVersion = () => {
        if (showDeleteConfirmationDialog) {
            let userToken = token?.token;
            if (userToken) {
                setFetchingState(true);
                ApiHelper.deleteAppVersion(userToken, showDeleteConfirmationDialog, controller, fetchData, saveErrorHandler)
            }
            setShowDeleteConfirmationDialog(null);
        }
    }
    return (<div>
        <Breadcrumbs/>
        {isFetching && <Loader/>}
        {!isFetching && hasError &&
            <FetchError onRetry={() => fetchData()}/>
        }
        {!isFetching && !hasError && apps &&
            <Fragment>
                <div className="data-list-container">
                    <div className="data-list">
                        <div className="data-list-title">{t("versions")}</div>
                        <table>
                            <thead>
                            <tr className="table-header">
                                <th>{t("date")}</th>
                                <th>{t("version")}</th>
                                <th/>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {apps.map(a => (
                                <tr key={`tr-${a.id}`}>
                                    <td>{formatUtcTimestamp(a.releaseTimestamp)}</td>
                                    <td>{a.version}</td>
                                    <td>
                                        <div
                                            className="d-flex justify-content-center align-items-center input-text link"
                                            onClick={() => onDownloadClick(a.id)}>{t("download")}<DownloadIcon
                                            className="mx-2"/></div>
                                    </td>
                                    <td>
                                        <div
                                            className="d-flex justify-content-center align-items-center input-text link"
                                            onClick={() => onDeleteClick(a.id)}>{t("delete")}<DeleteCommentIcon
                                            className="mx-2"/></div>
                                    </td>
                                </tr>))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="details-controls d-flex justify-content-center my-2">
                        <div className="d-flex justify-content-center mt-4">
                            <Button text={t("add")} danger={false} onClick={addApp}/>
                        </div>
                    </div>
                </div>
            </Fragment>
        }
        {(!hasError && !isFetching && hasSaveError) &&
            <Toast text={t("connection_problem")} isError={true}/>
        }
        {showDeleteConfirmationDialog &&
            <DialogConfirmation titleText={t("delete_version_title")}
                                messageText={t("delete_version_description")}
                                okButtonText={t("delete")} cancelButtonText={t("cancel")}
                                okButtonClickHandler={deleteVersion}
                                cancelButtonClickHandler={() => setShowDeleteConfirmationDialog(null)}/>}
        <input type="file" accept="*/*" onChange={onSelectFile} ref={uploadFileInput}
               style={{display: "none"}}/>
    </div>);
}
