export const DEVICE_ROUTE = "/device";
export const MOBILE_APP_ROUTE = "/app";
export const ACCOUNT_ROUTE = "/account";
export const GUEST_ROUTE = "/guest";
export const SIGN_IN_ROUTE = "/signin";
export const SIGN_UP_ROUTE =  "/signup";
export const RESTORE_ROUTE = "/restore";
export const RESET_ROUTE = "/reset";
export const BLOCKED_ROUTE = "/blocked";
export const NOT_CONFIRMED_ROUTE = "/notconfirmed";
export const SHARE_ROUTE = "/share";
export const HOME_ROUTE = "/";
export const MY_PATIENTS_CLEAR_ROUTE = "/mypatients";
export const MY_PATIENTS_ROUTE = "/mypatients/p";

export const MY_RECORDS_CLEAR_ROUTE = "/myrecords";
export const MY_RECORDS_ROUTE = "/myrecords/r";

export const SHARED_PATIENTS_CLEAR_ROUTE = "/shared-patients";
export const SHARED_PATIENTS_ROUTE = "/shared-patients/p";
export const SHARED_RECORDS_CLEAR_ROUTE = "/shared-records";
export const SHARED_RECORDS_ROUTE = "/shared-records/r";
export const PATIENTS_CLEAR_ROUTE = "/patients";
export const PATIENTS_ROUTE = "/patients/p";
export const RECORDS_CLEAR_ROUTE = "/records";
export const RECORDS_ROUTE = "/records/r";
export const USERS_CLEAR_ROUTE = "/users";
export const USERS_ROUTE = "/users/u";
export const DASHBOARD_ROUTE = "/dashboard";
export const PROFILE_ROUTE = "/profile";
export const SETTINGS_ROUTE = "/settings";
export const ACCESS_CONTROL_ROUTE = `${SETTINGS_ROUTE}/access`;
export const NOTIFICATION_SETTINGS_ROUTE = `${SETTINGS_ROUTE}/notifications`;

export const ADMIN_ROUTE = "/admin";

export const BINORA_APP_ROUTE = `${ADMIN_ROUTE}/apps_binora`;
export const BINORA_LT_ECG_APP_ROUTE = `${ADMIN_ROUTE}/apps_binora_lt_ecg`;
export const STATISTIC_ROUTE = `${ADMIN_ROUTE}/statistic`;
export const REMOVE_DEMO_DATA_ROUTE = `${ADMIN_ROUTE}/remove-demo`;
export const REMOVE_INACTIVE_USERS_ROUTE = `${ADMIN_ROUTE}/remove-inactive-users`;
export const UPGRADE_ATTACHMENTS_DATA_ROUTE = `${ADMIN_ROUTE}/upgrade-attachments`;
