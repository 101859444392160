import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import React from "react";
import {
    BINORA_APP_ROUTE,
    BINORA_LT_ECG_APP_ROUTE,
    REMOVE_DEMO_DATA_ROUTE,
    REMOVE_INACTIVE_USERS_ROUTE,
    STATISTIC_ROUTE,
    UPGRADE_ATTACHMENTS_DATA_ROUTE
} from "../../../routes";
import {KeyboardArrowRight} from "@mui/icons-material";

function Administration(){
    const {t} = useTranslation();
    const history = useNavigate();

    const onBinoraAppClick = () => {
        history(BINORA_APP_ROUTE);
    }
    const onBinoraLtEcgAppClick = () => {
        history(BINORA_LT_ECG_APP_ROUTE);
    }

    const onStatisticClick = () => {
        history(STATISTIC_ROUTE);
    }
    const onInactiveUsersClick = () => {
        history(REMOVE_INACTIVE_USERS_ROUTE);
    }
    const onDemoDataClick = () => {
        history(REMOVE_DEMO_DATA_ROUTE);
    }

    const onUpgradeAttachmentsClick = () => {
        history(UPGRADE_ATTACHMENTS_DATA_ROUTE);
    }

    return (
        <div>
        <Breadcrumbs/>
        <div className="d-flex flex-column align-items-center">
            <div className="details-table my-2">
                <table>
                    <tbody>
                    <tr>
                        <th>{t("mobile_app")}</th>
                        <td>
                            <div className="d-flex justify-content-between align-items-center input-text link"
                                 onClick={onBinoraAppClick}>Binora<KeyboardArrowRight/></div>
                            <div className="d-flex justify-content-between align-items-center input-text link"
                                 onClick={onBinoraLtEcgAppClick}>Binora LT ECG<KeyboardArrowRight/></div>
                        </td>
                    </tr>
                    <tr>
                        <th>{t("statistic")}</th>
                        <td>
                            <div className="d-flex justify-content-between align-items-center input-text link"
                                 onClick={onStatisticClick}>{t("view")}<KeyboardArrowRight/></div>
                        </td>
                    </tr>
                    <tr>
                        <th>{t("inactive_users")}</th>
                        <td>
                            <div className="d-flex justify-content-between align-items-center input-text link"
                                 onClick={onInactiveUsersClick}>{t("view")}<KeyboardArrowRight/></div>
                        </td>
                    </tr>
                    <tr>
                        <th>{t("demo_data")}</th>
                        <td>
                            <div className="d-flex justify-content-between align-items-center input-text link"
                                 onClick={onDemoDataClick}>{t("view")}<KeyboardArrowRight/></div>
                        </td>
                    </tr>
                    <tr>
                        <th>{t("attachment_upgrade")}</th>
                        <td>
                            <div className="d-flex justify-content-between align-items-center input-text link"
                                 onClick={onUpgradeAttachmentsClick}>{t("view")}<KeyboardArrowRight/></div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </div>);
}

export default Administration;
